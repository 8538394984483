export default function(W) {
  function M(d, c, e, h, a) {
    for (
      var m = 0,
        b = 0,
        v = 0,
        n = 0,
        q,
        g,
        x = 0,
        K = 0,
        k,
        u = (k = q = 0),
        l = 0,
        r = 0,
        I = 0,
        t = 0,
        B = e.length,
        J = B - 1,
        y,
        f = '',
        p = '',
        F = '',
        G = '',
        C;
      l < B;

    ) {
      g = e.charCodeAt(l)
      l === J &&
        0 !== b + n + v + m &&
        (0 !== b && (g = 47 === b ? 10 : 47), (n = v = m = 0), B++, J++)
      if (0 === b + n + v + m) {
        if (l === J && (0 < r && (f = f.replace(N, '')), 0 < f.trim().length)) {
          switch (g) {
            case 32:
            case 9:
            case 59:
            case 13:
            case 10:
              break
            default:
              f += e.charAt(l)
          }
          g = 59
        }
        switch (g) {
          case 123:
            f = f.trim()
            q = f.charCodeAt(0)
            k = 1
            for (t = ++l; l < B; ) {
              switch ((g = e.charCodeAt(l))) {
                case 123:
                  k++
                  break
                case 125:
                  k--
                  break
                case 47:
                  switch ((g = e.charCodeAt(l + 1))) {
                    case 42:
                    case 47:
                      a: {
                        for (u = l + 1; u < J; ++u)
                          switch (e.charCodeAt(u)) {
                            case 47:
                              if (
                                42 === g &&
                                42 === e.charCodeAt(u - 1) &&
                                l + 2 !== u
                              ) {
                                l = u + 1
                                break a
                              }
                              break
                            case 10:
                              if (47 === g) {
                                l = u + 1
                                break a
                              }
                          }
                        l = u
                      }
                  }
                  break
                case 91:
                  g++
                case 40:
                  g++
                case 34:
                case 39:
                  for (; l++ < J && e.charCodeAt(l) !== g; );
              }
              if (0 === k) break
              l++
            }
            k = e.substring(t, l)
            0 === q && (q = (f = f.replace(ca, '').trim()).charCodeAt(0))
            switch (q) {
              case 64:
                0 < r && (f = f.replace(N, ''))
                g = f.charCodeAt(1)
                switch (g) {
                  case 100:
                  case 109:
                  case 115:
                  case 45:
                    r = c
                    break
                  default:
                    r = O
                }
                k = M(c, r, k, g, a + 1)
                t = k.length
                0 < A &&
                  ((r = X(O, f, I)),
                  (C = H(3, k, r, c, D, z, t, g, a, h)),
                  (f = r.join('')),
                  void 0 !== C &&
                    0 === (t = (k = C.trim()).length) &&
                    ((g = 0), (k = '')))
                if (0 < t)
                  switch (g) {
                    case 115:
                      f = f.replace(da, ea)
                    case 100:
                    case 109:
                    case 45:
                      k = f + '{' + k + '}'
                      break
                    case 107:
                      f = f.replace(fa, '$1 $2')
                      k = f + '{' + k + '}'
                      k =
                        1 === w || (2 === w && L('@' + k, 3))
                          ? '@-webkit-' + k + '@' + k
                          : '@' + k
                      break
                    default:
                      ;(k = f + k), 112 === h && (k = ((p += k), ''))
                  }
                else k = ''
                break
              default:
                k = M(c, X(c, f, I), k, h, a + 1)
            }
            F += k
            k = I = r = u = q = 0
            f = ''
            g = e.charCodeAt(++l)
            break
          case 125:
          case 59:
            f = (0 < r ? f.replace(N, '') : f).trim()
            if (1 < (t = f.length))
              switch (
                (0 === u &&
                  ((q = f.charCodeAt(0)), 45 === q || (96 < q && 123 > q)) &&
                  (t = (f = f.replace(' ', ':')).length),
                0 < A &&
                  void 0 !== (C = H(1, f, c, d, D, z, p.length, h, a, h)) &&
                  0 === (t = (f = C.trim()).length) &&
                  (f = '\x00\x00'),
                (q = f.charCodeAt(0)),
                (g = f.charCodeAt(1)),
                q)
              ) {
                case 0:
                  break
                case 64:
                  if (105 === g || 99 === g) {
                    G += f + e.charAt(l)
                    break
                  }
                default:
                  58 !== f.charCodeAt(t - 1) &&
                    (p += P(f, q, g, f.charCodeAt(2)))
              }
            I = r = u = q = 0
            f = ''
            g = e.charCodeAt(++l)
        }
      }
      switch (g) {
        case 13:
        case 10:
          47 === b
            ? (b = 0)
            : 0 === 1 + q &&
              107 !== h &&
              0 < f.length &&
              ((r = 1), (f += '\x00'))
          0 < A * Y && H(0, f, c, d, D, z, p.length, h, a, h)
          z = 1
          D++
          break
        case 59:
        case 125:
          if (0 === b + n + v + m) {
            z++
            break
          }
        default:
          z++
          y = e.charAt(l)
          switch (g) {
            case 9:
            case 32:
              if (0 === n + m + b)
                switch (x) {
                  case 44:
                  case 58:
                  case 9:
                  case 32:
                    y = ''
                    break
                  default:
                    32 !== g && (y = ' ')
                }
              break
            case 0:
              y = '\\0'
              break
            case 12:
              y = '\\f'
              break
            case 11:
              y = '\\v'
              break
            case 38:
              0 === n + b + m && ((r = I = 1), (y = '\f' + y))
              break
            case 108:
              if (0 === n + b + m + E && 0 < u)
                switch (l - u) {
                  case 2:
                    112 === x && 58 === e.charCodeAt(l - 3) && (E = x)
                  case 8:
                    111 === K && (E = K)
                }
              break
            case 58:
              0 === n + b + m && (u = l)
              break
            case 44:
              0 === b + v + n + m && ((r = 1), (y += '\r'))
              break
            case 34:
            case 39:
              0 === b && (n = n === g ? 0 : 0 === n ? g : n)
              break
            case 91:
              0 === n + b + v && m++
              break
            case 93:
              0 === n + b + v && m--
              break
            case 41:
              0 === n + b + m && v--
              break
            case 40:
              if (0 === n + b + m) {
                if (0 === q)
                  switch (2 * x + 3 * K) {
                    case 533:
                      break
                    default:
                      q = 1
                  }
                v++
              }
              break
            case 64:
              0 === b + v + n + m + u + k && (k = 1)
              break
            case 42:
            case 47:
              if (!(0 < n + m + v))
                switch (b) {
                  case 0:
                    switch (2 * g + 3 * e.charCodeAt(l + 1)) {
                      case 235:
                        b = 47
                        break
                      case 220:
                        ;(t = l), (b = 42)
                    }
                    break
                  case 42:
                    47 === g &&
                      42 === x &&
                      t + 2 !== l &&
                      (33 === e.charCodeAt(t + 2) &&
                        (p += e.substring(t, l + 1)),
                      (y = ''),
                      (b = 0))
                }
          }
          0 === b && (f += y)
      }
      K = x
      x = g
      l++
    }
    t = p.length
    if (0 < t) {
      r = c
      if (
        0 < A &&
        ((C = H(2, p, r, d, D, z, t, h, a, h)),
        void 0 !== C && 0 === (p = C).length)
      )
        return G + p + F
      p = r.join(',') + '{' + p + '}'
      if (0 !== w * E) {
        2 !== w || L(p, 2) || (E = 0)
        switch (E) {
          case 111:
            p = p.replace(ha, ':-moz-$1') + p
            break
          case 112:
            p =
              p.replace(Q, '::-webkit-input-$1') +
              p.replace(Q, '::-moz-$1') +
              p.replace(Q, ':-ms-input-$1') +
              p
        }
        E = 0
      }
    }
    return G + p + F
  }
  function X(d, c, e) {
    var h = c.trim().split(ia)
    c = h
    var a = h.length,
      m = d.length
    switch (m) {
      case 0:
      case 1:
        var b = 0
        for (d = 0 === m ? '' : d[0] + ' '; b < a; ++b)
          c[b] = Z(d, c[b], e, m).trim()
        break
      default:
        var v = (b = 0)
        for (c = []; b < a; ++b)
          for (var n = 0; n < m; ++n) c[v++] = Z(d[n] + ' ', h[b], e, m).trim()
    }
    return c
  }
  function Z(d, c, e) {
    var h = c.charCodeAt(0)
    33 > h && (h = (c = c.trim()).charCodeAt(0))
    switch (h) {
      case 38:
        return c.replace(F, '$1' + d.trim())
      case 58:
        return d.trim() + c.replace(F, '$1' + d.trim())
      default:
        if (0 < 1 * e && 0 < c.indexOf('\f'))
          return c.replace(F, (58 === d.charCodeAt(0) ? '' : '$1') + d.trim())
    }
    return d + c
  }
  function P(d, c, e, h) {
    var a = d + ';',
      m = 2 * c + 3 * e + 4 * h
    if (944 === m) {
      d = a.indexOf(':', 9) + 1
      var b = a.substring(d, a.length - 1).trim()
      b = a.substring(0, d).trim() + b + ';'
      return 1 === w || (2 === w && L(b, 1)) ? '-webkit-' + b + b : b
    }
    if (0 === w || (2 === w && !L(a, 1))) return a
    switch (m) {
      case 1015:
        return 97 === a.charCodeAt(10) ? '-webkit-' + a + a : a
      case 951:
        return 116 === a.charCodeAt(3) ? '-webkit-' + a + a : a
      case 963:
        return 110 === a.charCodeAt(5) ? '-webkit-' + a + a : a
      case 1009:
        if (100 !== a.charCodeAt(4)) break
      case 969:
      case 942:
        return '-webkit-' + a + a
      case 978:
        return '-webkit-' + a + '-moz-' + a + a
      case 1019:
      case 983:
        return '-webkit-' + a + '-moz-' + a + '-ms-' + a + a
      case 883:
        if (45 === a.charCodeAt(8)) return '-webkit-' + a + a
        if (0 < a.indexOf('image-set(', 11))
          return a.replace(ja, '$1-webkit-$2') + a
        break
      case 932:
        if (45 === a.charCodeAt(4))
          switch (a.charCodeAt(5)) {
            case 103:
              return (
                '-webkit-box-' +
                a.replace('-grow', '') +
                '-webkit-' +
                a +
                '-ms-' +
                a.replace('grow', 'positive') +
                a
              )
            case 115:
              return (
                '-webkit-' + a + '-ms-' + a.replace('shrink', 'negative') + a
              )
            case 98:
              return (
                '-webkit-' +
                a +
                '-ms-' +
                a.replace('basis', 'preferred-size') +
                a
              )
          }
        return '-webkit-' + a + '-ms-' + a + a
      case 964:
        return '-webkit-' + a + '-ms-flex-' + a + a
      case 1023:
        if (99 !== a.charCodeAt(8)) break
        b = a
          .substring(a.indexOf(':', 15))
          .replace('flex-', '')
          .replace('space-between', 'justify')
        return '-webkit-box-pack' + b + '-webkit-' + a + '-ms-flex-pack' + b + a
      case 1005:
        return ka.test(a)
          ? a.replace(aa, ':-webkit-') + a.replace(aa, ':-moz-') + a
          : a
      case 1e3:
        b = a.substring(13).trim()
        c = b.indexOf('-') + 1
        switch (b.charCodeAt(0) + b.charCodeAt(c)) {
          case 226:
            b = a.replace(G, 'tb')
            break
          case 232:
            b = a.replace(G, 'tb-rl')
            break
          case 220:
            b = a.replace(G, 'lr')
            break
          default:
            return a
        }
        return '-webkit-' + a + '-ms-' + b + a
      case 1017:
        if (-1 === a.indexOf('sticky', 9)) break
      case 975:
        c = (a = d).length - 10
        b = (33 === a.charCodeAt(c) ? a.substring(0, c) : a)
          .substring(d.indexOf(':', 7) + 1)
          .trim()
        switch ((m = b.charCodeAt(0) + (b.charCodeAt(7) | 0))) {
          case 203:
            if (111 > b.charCodeAt(8)) break
          case 115:
            a = a.replace(b, '-webkit-' + b) + ';' + a
            break
          case 207:
          case 102:
            a =
              a.replace(b, '-webkit-' + (102 < m ? 'inline-' : '') + 'box') +
              ';' +
              a.replace(b, '-webkit-' + b) +
              ';' +
              a.replace(b, '-ms-' + b + 'box') +
              ';' +
              a
        }
        return a + ';'
      case 938:
        if (45 === a.charCodeAt(5))
          switch (a.charCodeAt(6)) {
            case 105:
              return (
                (b = a.replace('-items', '')),
                '-webkit-' + a + '-webkit-box-' + b + '-ms-flex-' + b + a
              )
            case 115:
              return '-webkit-' + a + '-ms-flex-item-' + a.replace(ba, '') + a
            default:
              return (
                '-webkit-' +
                a +
                '-ms-flex-line-pack' +
                a.replace('align-content', '').replace(ba, '') +
                a
              )
          }
        break
      case 973:
      case 989:
        if (45 !== a.charCodeAt(3) || 122 === a.charCodeAt(4)) break
      case 931:
      case 953:
        if (!0 === la.test(d))
          return 115 === (b = d.substring(d.indexOf(':') + 1)).charCodeAt(0)
            ? P(d.replace('stretch', 'fill-available'), c, e, h).replace(
                ':fill-available',
                ':stretch'
              )
            : a.replace(b, '-webkit-' + b) +
                a.replace(b, '-moz-' + b.replace('fill-', '')) +
                a
        break
      case 962:
        if (
          ((a =
            '-webkit-' + a + (102 === a.charCodeAt(5) ? '-ms-' + a : '') + a),
          211 === e + h &&
            105 === a.charCodeAt(13) &&
            0 < a.indexOf('transform', 10))
        )
          return (
            a.substring(0, a.indexOf(';', 27) + 1).replace(ma, '$1-webkit-$2') +
            a
          )
    }
    return a
  }
  function L(d, c) {
    var e = d.indexOf(1 === c ? ':' : '{'),
      h = d.substring(0, 3 !== c ? e : 10)
    e = d.substring(e + 1, d.length - 1)
    return R(2 !== c ? h : h.replace(na, '$1'), e, c)
  }
  function ea(d, c) {
    var e = P(c, c.charCodeAt(0), c.charCodeAt(1), c.charCodeAt(2))
    return e !== c + ';'
      ? e.replace(oa, ' or ($1)').substring(4)
      : '(' + c + ')'
  }
  function H(d, c, e, h, a, m, b, v, n, q) {
    for (var g = 0, x = c, w; g < A; ++g)
      switch ((w = S[g].call(B, d, x, e, h, a, m, b, v, n, q))) {
        case void 0:
        case !1:
        case !0:
        case null:
          break
        default:
          x = w
      }
    if (x !== c) return x
  }
  function T(d) {
    switch (d) {
      case void 0:
      case null:
        A = S.length = 0
        break
      default:
        if ('function' === typeof d) S[A++] = d
        else if ('object' === typeof d)
          for (var c = 0, e = d.length; c < e; ++c) T(d[c])
        else Y = !!d | 0
    }
    return T
  }
  function U(d) {
    d = d.prefix
    void 0 !== d &&
      ((R = null),
      d ? ('function' !== typeof d ? (w = 1) : ((w = 2), (R = d))) : (w = 0))
    return U
  }
  function B(d, c) {
    var e = d
    33 > e.charCodeAt(0) && (e = e.trim())
    V = e
    e = [V]
    if (0 < A) {
      var h = H(-1, c, e, e, D, z, 0, 0, 0, 0)
      void 0 !== h && 'string' === typeof h && (c = h)
    }
    var a = M(O, e, c, 0, 0)
    0 < A &&
      ((h = H(-2, a, e, e, D, z, a.length, 0, 0, 0)), void 0 !== h && (a = h))
    V = ''
    E = 0
    z = D = 1
    return a
  }
  var ca = /^\0+/g,
    N = /[\0\r\f]/g,
    aa = /: */g,
    ka = /zoo|gra/,
    ma = /([,: ])(transform)/g,
    ia = /,\r+?/g,
    F = /([\t\r\n ])*\f?&/g,
    fa = /@(k\w+)\s*(\S*)\s*/,
    Q = /::(place)/g,
    ha = /:(read-only)/g,
    G = /[svh]\w+-[tblr]{2}/,
    da = /\(\s*(.*)\s*\)/g,
    oa = /([\s\S]*?);/g,
    ba = /-self|flex-/g,
    na = /[^]*?(:[rp][el]a[\w-]+)[^]*/,
    la = /stretch|:\s*\w+\-(?:conte|avail)/,
    ja = /([^-])(image-set\()/,
    z = 1,
    D = 1,
    E = 0,
    w = 1,
    O = [],
    S = [],
    A = 0,
    R = null,
    Y = 0,
    V = ''
  B.use = T
  B.set = U
  void 0 !== W && U(W)
  return B
}
